import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Admin_icon from '../assets/photo/admin.jpg';
import './layout.css'
import {
    faChevronLeft, faChevronDown, faListUl, faBuildingColumns, faIndent, faFileInvoice, faFileCode,faUsersGear,
    faStore, faGear, faMobileScreen, faGlobe, faWrench, faLifeRing, faGaugeHigh,faEye,faPenToSquare,
    faEnvelope, faBell,faCreditCard , faGamepad,faSignOutAlt ,faDatabase , faFutbol ,faBullhorn,faTicketAlt , faRing, faKey, faCoins,faChartLine , faPuzzlePiece, faTachometerAlt , faUniversalAccess, faTree,faMessage, faListCheck, faCircleInfo, faPeopleGroup, faVideo,faBars,faX,faUsers
} from '@fortawesome/free-solid-svg-icons'

export default function HeaderPage() {
    const navigate = useNavigate();
    const [headerToggle, setHeaderToggle] = useState(false)
    const [headerNavManu, setheaderNavManu] = useState(true)
    const [render, setRender] = useState(true)
    const [plus, setPlus] = useState(true)
    const [plus2, setPlus2] = useState(true)
    const [plus3, setPlus3] = useState(true)
    const [plus4, setPlus4] = useState(true)
    const [plus5, setPlus5] = useState(true)
    const [plus6, setPlus6] = useState(true)
    const [plus7, setPlus7] = useState(true)
    const [plus8, setPlus8] = useState(true)
    const [plus9, setPlus9] = useState(true)
    const [plus10, setPlus10] = useState(true)
    const [plus11, setPlus11] = useState(true)
    const [plus12, setPlus12] = useState(true)
    const [sidebar, setSidebar] = useState('')
    const updateActive = () => {
        setRender(!render)
    }
    const headerTogglehandle = () => {
        setHeaderToggle(!headerToggle)
        setheaderNavManu(!headerNavManu)
    }
    var body_pd = document.getElementById('body-pd')
    useEffect(() => {
        console.warn('headerToggle ::',headerToggle);
        window.innerWidth >= 768 && (headerToggle ? body_pd.classList.add('body-pd') : body_pd.classList.remove('body-pd'))
        headerToggle && document.getElementById('header') !== null ? document.getElementById('header').classList.add('body-pd'): document.getElementById('header').classList.remove('body-pd')
    }, [headerToggle])
    
    const pathname = window.location.pathname;
    const logoutHandle=()=>{
        Swal.fire({
            icon: 'warning',
            title: 'You wont be able to revert this!',
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              console.log("from swite alert");
      
              Swal.fire('logout Success', '', 'success')
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
    }
    return(
        <>
        <div>
            <header className="header mb-4 xxxx dropdown" id="header">
                <div onClick={headerTogglehandle} className={`header_toggle ${headerNavManu ? '' : 'd-none'}`} id="header-toggle">{headerNavManu ? <FontAwesomeIcon icon={faBars} /> : <FontAwesomeIcon icon={faX} />} </div>
                <div className="d-flex align-items-center dropdown-toggle ms-auto" data-bs-toggle="dropdown"><span className="header_img"> <img src={Admin_icon} alt="admin icon" /> </span> <span className="ms-1">Skipper</span> </div>

                <ul  className="dropdown-menu dropdown-menu-end" style={{width: 'auto',padding: '0, 2rem'}} aria-labelledby="dropdownMenuButton1">
                    <li><Link className="dropdown-item" to="/profileSetting">Profile</Link></li>
                    <li><Link className="dropdown-item" to="/general-setting">Setting</Link></li>
                    <li className="dropdown-item" onClick={logoutHandle}>Log Out</li>
                </ul>
            </header>
            
            <div className="manubar">
                <div className={`l-navbar ${ headerToggle ? "show" : " "}`} id="nav-bar">
                <div onClick={headerTogglehandle} className={`header_toggle text-white float-end ${headerNavManu ? 'd-none' : ''}`} id="header-toggle">{headerNavManu ? <FontAwesomeIcon icon={faBars} /> : <FontAwesomeIcon icon={faX} />} </div>
                    <nav className="nav">
                        <div> <a href="#" className="nav_logo"> <span className="header_img" style={{width:'30px',height:'30px'}}> <img src={Admin_icon} alt="admin icon" /> </span> <span className="nav_logo-name">Admin Panel</span> </a>
                            <div className="nav_list">

                                <Link onClick={updateActive} to="/admin/dashboard" className={`nav_link ${pathname == "/admin/dashboard" ? 'active' : '' }`}>
                                    <FontAwesomeIcon icon={faTachometerAlt}/>
                                    <span className="nav_name">Dashboard</span>
                                </Link>

                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <p className="accordion-header" id="headingOne">
                                            <Link onClick={() =>{setPlus6(!plus6);updateActive()}} to="#" type="button" data-bs-toggle="collapse" data-bs-target="#gameManagement" aria-expanded="true" aria-controls="paymentSystem" className={`nav_link `}>
                                            <FontAwesomeIcon icon={faGamepad } />
                                                <span className="nav_name ">Game Management <b>{plus6 ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronDown} />}</b></span>
                                            </Link>
                                        </p>
                                        <div id="gameManagement" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <Link onClick={updateActive} to="/admin/leagues" className={`nav_link ${pathname == "/admin/role" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faPenToSquare} />
                                                    <span className="nav_name">Leagues</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/tournament" className={`nav_link ${pathname == "/admin/tournament" ? 'active' : ''}`}>
                                                <FontAwesomeIcon icon={faEye} />
                                                    <span className="nav_name">Tournament</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/players" className={`nav_link ${pathname == "/admin/players" ? 'active' : ''}`}>
                                                <FontAwesomeIcon icon={faEye} />
                                                    <span className="nav_name">Players</span>
                                                </Link>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <p className="accordion-header" id="headingOne">
                                            <Link onClick={() =>{setPlus7(!plus7);updateActive()}} to="#" type="button" data-bs-toggle="collapse" data-bs-target="#match" aria-expanded="true" aria-controls="help" className={`nav_link `}>
                                                <FontAwesomeIcon icon={faFutbol } />
                                                <span className="nav_name ">Match <b>{plus7 ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronDown} />}</b></span>
                                            </Link>
                                        </p>
                                        <div id="match" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <Link onClick={updateActive} to="/admin/match/all-event-match" className={`nav_link ${pathname == "/admin/match/all-event-match" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">All Event</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/match/running-event-match" className={`nav_link ${pathname == "/admin/match/running-event-match" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Running Event</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/match/upcoming-event-match" className={`nav_link ${pathname == "/admin/match/upcoming-event-match" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Upcoming Event</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <p className="accordion-header" id="headingOne">
                                            <Link onClick={() =>{setPlus8(!plus8);updateActive()}} to="#" type="button" data-bs-toggle="collapse" data-bs-target="#bets-log" aria-expanded="true" aria-controls="help" className={`nav_link `}>
                                                <FontAwesomeIcon icon={faCoins} />
                                                <span className="nav_name ">Bets Log <b>{plus7 ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronDown} />}</b></span>
                                            </Link>
                                        </p>
                                        <div id="bets-log" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <Link onClick={updateActive} to="/admin/all-bets-log" className={`nav_link ${pathname == "/admin/all-bets-log" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">All Bets</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/pending-bets-log" className={`nav_link ${pathname == "/admin/pending-bets-log" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Pending Bets</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/won-bets-log" className={`nav_link ${pathname == "/admin/won-bets-log" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Won Bets</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/lost-bets-log" className={`nav_link ${pathname == "/admin/lost-bets-log" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Lost Bets</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/refunded-bets-log" className={`nav_link ${pathname == "/admin/refunded-bets-log" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Refunded Bets</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Link onClick={updateActive} to="/admin/result" className={`nav_link ${pathname == "/admin/result" ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon={faChartLine } />
                                    <span className="nav_name">Results</span>
                                </Link>

                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <p className="accordion-header" id="headingOne">
                                            <Link onClick={() =>{setPlus10(!plus10);updateActive()}} to="#" type="button" data-bs-toggle="collapse" data-bs-target="#acl-manage" aria-expanded="true" aria-controls="help" className={`nav_link `}>
                                                <FontAwesomeIcon icon={faUsersGear} />
                                                <span className="nav_name ">ACL Manage <b>{plus7 ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronDown} />}</b></span>
                                            </Link>
                                        </p>
                                        <div id="acl-manage" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <Link onClick={updateActive} to="/admin/permission" className={`nav_link ${pathname == "/admin/permission" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Permissions</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/auth-users" className={`nav_link ${pathname == "/admin/auth-users" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Auth Users</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/roles" className={`nav_link ${pathname == "/admin/roles" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Roles</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <p className="accordion-header" id="headingOne">
                                            <Link onClick={() =>{setPlus12(!plus12);updateActive()}} to="#" type="button" data-bs-toggle="collapse" data-bs-target="#deposit" aria-expanded="true" aria-controls="help" className={`nav_link `}>
                                                <FontAwesomeIcon icon={faUsersGear} />
                                                <span className="nav_name ">Deposits <b>{plus12 ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronDown} />}</b></span>
                                            </Link>
                                        </p>
                                        <div id="deposit" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <Link onClick={updateActive} to="/admin/deposit-log" className={`nav_link ${pathname == "/admin/deposit-log" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Deposit Log</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/pending-deposit-log" className={`nav_link ${pathname == "/admin/pending-deposit-log" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Pending Deposit</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/accepted-deposit-log" className={`nav_link ${pathname == "/admin/accepted-deposit-log" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Accepted Deposit</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/rejected-deposit-log" className={`nav_link ${pathname == "/admin/rejected-deposit-log" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Rejected Deposit</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <p className="accordion-header" id="headingOne">
                                            <Link onClick={() =>{setPlus9(!plus9);updateActive()}} to="#" type="button" data-bs-toggle="collapse" data-bs-target="#manage-users" aria-expanded="true" aria-controls="help" className={`nav_link `}>
                                                <FontAwesomeIcon icon={faUsersGear} />
                                                <span className="nav_name ">Manage Users <b>{plus7 ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronDown} />}</b></span>
                                            </Link>
                                        </p>
                                        <div id="manage-users" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <Link onClick={updateActive} to="/admin/manage-all-users" className={`nav_link ${pathname == "/admin/manage-all-users" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">All Users</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/manage-active-users" className={`nav_link ${pathname == "/admin/manage-active-users" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Active Users</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/manage-banned-users" className={`nav_link ${pathname == "/admin/manage-banned-users" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Banned Users</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/manage-unverified-users" className={`nav_link ${pathname == "/admin/manage-unverified-users" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Unverified Users</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Link onClick={updateActive} to="/admin/ticket" className={`nav_link ${pathname == "/admin/ticket" ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon={faTicketAlt } />
                                    <span className="nav_name">Tickets</span>
                                </Link>

                                <Link to="/admin/payment" onClick={updateActive} className={`nav_link ${pathname == "/admin/payment" ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon={faCreditCard } />
                                    <span className="nav_name">Payments</span>
                                </Link>
                                {/* <Link onClick={updateActive} to="/bank-payment" className={`nav_link ${pathname == "/bank-payment" ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon={faBuildingColumns} />
                                    <span className="nav_name">Banks Payments</span>
                                </Link> */}
                                <Link onClick={updateActive} to="/admin/stored-data" className={`nav_link ${pathname == "/admin/stored-data" ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon={faDatabase } />
                                    <span className="nav_name">Stored Data</span>
                                </Link>
                                <Link onClick={updateActive} to="/admin/manage-invoice" className={`nav_link ${pathname == "/admin/manage-invoice" ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon={faFileInvoice} />
                                    <span className="nav_name">Manage Invoice</span>
                                </Link>
                                <Link onClick={updateActive} to="/admin/banner" className={`nav_link ${pathname == "/admin/banner" ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon={faStore} />
                                    <span className="nav_name">Banner</span>
                                </Link>
                                
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <p className="accordion-header" id="headingOne">
                                            <Link onClick={() => {setPlus(!plus);updateActive()}} to="#" type="button" data-bs-toggle="collapse" data-bs-target="#paymentSystem" aria-expanded="true" aria-controls="paymentSystem" className={`nav_link `}>
                                                <FontAwesomeIcon icon={faGear} />
                                                <span className="nav_name ">Payment Setting <b>{plus ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronDown} />}</b></span>
                                            </Link>
                                        </p>
                                        <div id="paymentSystem" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <Link onClick={updateActive} to="/admin/mobail-bank" className={`nav_link ${pathname == "/admin/mobail-bank" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faMobileScreen} />
                                                    <span className="nav_name">Mobail Bank</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/bank-transfer" className={`nav_link ${pathname == "/admin/bank-transfer" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faBuildingColumns} />
                                                    <span className="nav_name">Bank Transfar</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/international" className={`nav_link ${pathname == "/admin/international" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGlobe} />
                                                    <span className="nav_name">International</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <p className="accordion-header" id="headingOne">
                                            <Link onClick={() => {setPlus2(!plus2);updateActive()}} to="#" type="button" data-bs-toggle="collapse" data-bs-target="#systemSetting" aria-expanded="true" aria-controls="systemSetting" className={`nav_link `}>
                                                <FontAwesomeIcon icon={faWrench} />
                                                <span className="nav_name ">System Setting <b>{plus2 ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronDown} />}</b></span>
                                            </Link>
                                        </p>
                                        <div id="systemSetting" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <Link onClick={updateActive} to="/system-setting/app-setting" className={`nav_link ${pathname == "/system-setting/app-setting" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faMobileScreen} />
                                                    <span className="nav_name">Android App</span>
                                                </Link>
                                                
                                                <Link onClick={updateActive} to="/admin/system-template" className={`nav_link ${pathname == "/admin/system-template" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faGaugeHigh} />
                                                    <span className="nav_name">Template</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/custom-css" className={`nav_link ${pathname == "/admin/custom-css" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faFileCode} />
                                                    <span className="nav_name">Custom CSS</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/mail" className={`nav_link ${pathname == "/admin/mail" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                    <span className="nav_name">Mail</span>
                                                </Link>
                                                <div className="accordion" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <p className="accordion-header" id="headingOne">
                                                            <Link onClick={() => {setPlus3(!plus3);updateActive()}} to="#" type="button" data-bs-toggle="collapse" data-bs-target="#notification" aria-expanded="true" aria-controls="notification" className={`nav_link `}>
                                                                <FontAwesomeIcon icon={faBell} />
                                                                <span className="nav_name ">Notifications <b>{plus3 ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronDown} />}</b></span>
                                                            </Link>
                                                        </p>
                                                        <div id="notification" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <Link onClick={updateActive} to="/admin/one-signal" className={`nav_link ${pathname == "/admin/one-signal" ? 'active' : ''}`}>
                                                                    <FontAwesomeIcon icon={faRing} />
                                                                    <span className="nav_name">One Signal</span>
                                                                </Link>
                                                                <Link onClick={updateActive} to="/admin/email" className={`nav_link ${pathname == "/admin/email" ? 'active' : ''}`}>
                                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                                    <span className="nav_name">Email</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <Link onClick={updateActive} to="/admin/active-liscence" className={`nav_link ${pathname == "/admin/active-license" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faKey} />
                                                    <span className="nav_name">Active license</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <p className="accordion-header" id="headingOne">
                                            <Link onClick={() => {setPlus4(!plus4);updateActive()}} to="#" type="button" data-bs-toggle="collapse" data-bs-target="#addons" aria-expanded="true" aria-controls="addons" className={`nav_link`}>
                                                <FontAwesomeIcon icon={faPuzzlePiece} />
                                                <span className="nav_name ">Addons <b>{plus4 ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronDown} />}</b></span>
                                            </Link>
                                        </p>
                                        <div id="addons" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <Link onClick={updateActive} to="/sms-transaction" className={`nav_link ${pathname == "/sms-transaction" ? 'active' : ''}`}>
                                                <FontAwesomeIcon icon={faMessage} />
                                                    <span className="nav_name">Sms Transaction</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/sms-setting/bulksmsbd" className={`nav_link ${pathname == "/sms-setting/bulksmsbd" ? 'active' : ''}`}>
                                                <FontAwesomeIcon icon={faMessage} />
                                                    <span className="nav_name">Sms List</span>
                                                </Link>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <p className="accordion-header" id="headingOne">
                                            <Link onClick={() =>{setPlus6(!plus6);updateActive()}} to="#" type="button" data-bs-toggle="collapse" data-bs-target="#rollManagement" aria-expanded="true" aria-controls="paymentSystem" className={`nav_link `}>
                                            <FontAwesomeIcon icon={faUsersGear} />
                                                <span className="nav_name ">Role Management <b>{plus6 ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronDown} />}</b></span>
                                            </Link>
                                        </p>
                                        <div id="rollManagement" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <Link onClick={updateActive} to="/admin/role" className={`nav_link ${pathname == "/admin/role" ? 'active' : ''}`}>
                                                    <FontAwesomeIcon icon={faPenToSquare} />
                                                    <span className="nav_name">Role</span>
                                                </Link>
                                                <Link onClick={updateActive} to="/admin/role-access" className={`nav_link ${pathname == "/admin/role-access" ? 'active' : ''}`}>
                                                <FontAwesomeIcon icon={faEye} />
                                                    <span className="nav_name">Role Access</span>
                                                </Link>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <Link onClick={updateActive} to="/theme-market" className={`nav_link ${pathname == "/theme-market" ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon={faStore} />
                                    <span className="nav_name">Theme Market</span>
                                </Link> */}
                                <Link onClick={updateActive} to="/admin/change-password" className={`nav_link ${pathname == "/admin/change-password" ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon={faGear} />
                                    <span className="nav_name">Change Password</span>
                                </Link>
                                {/* <Link onClick={updateActive} to="/activities" className={`nav_link ${pathname == "/activities" ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon={faListCheck} />
                                    <span className="nav_name">Activity Logs</span>
                                </Link> */}
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <p className="accordion-header" id="headingOne">
                                            <Link onClick={() =>{setPlus11(!plus11);updateActive()}} to="#" type="button" data-bs-toggle="collapse" data-bs-target="#help" aria-expanded="true" aria-controls="help" className={`nav_link `}>
                                                <FontAwesomeIcon icon={faCircleInfo} />
                                                <span className="nav_name ">Help <b>{plus11 ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronDown} />}</b></span>
                                            </Link>
                                        </p>
                                        <div id="help" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <Link onClick={updateActive} to="/general-setting" className={`nav_link `}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">General Setting</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <p className="accordion-header" id="headingOne">
                                            <Link onClick={() =>{setPlus5(!plus5);updateActive()}} to="#" type="button" data-bs-toggle="collapse" data-bs-target="#promotions" aria-expanded="true" aria-controls="promotions" className={`nav_link `}>
                                                <FontAwesomeIcon icon={faBullhorn} />
                                                <span className="nav_name ">Promotions <b>{plus5 ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronDown} />}</b></span>
                                            </Link>
                                        </p>
                                        <div id="promotions" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <Link onClick={updateActive} to="/admin/rewards" className={`nav_link `}>
                                                    <FontAwesomeIcon icon={faGear} />
                                                    <span className="nav_name">Rewards</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Link onClick={updateActive} to="/admin/setting" className={`nav_link ${pathname == "/admin/setting" ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon={faGear} />
                                    <span className="nav_name">Setting</span>
                                </Link>

                                <Link onClick={logoutHandle} to="#" className={`nav_link `}>
                                    <FontAwesomeIcon icon={faSignOutAlt } />
                                    <span className="nav_name">Logout</span>
                                </Link>
                            </div>
                        </div> 
                    </nav>
                </div>
            </div>
        </div>
        
        </>
    )
    
}