import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash, faDesktop  } from "@fortawesome/free-solid-svg-icons";
import ResultsList from "./components/List";
import { useNavigate } from "react-router-dom";
import ResultFormDialogue from "./components/Form";

export default function ManageUsersPage() {
  const [show, setShow] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate()
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setImagePreview(null);
    setShow(true);
  };
  const handleEdit = (row) => {
    console.log(`Edit clicked for ID: ${row.id}`);
    setShow(true);
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ID: ${id}?`
    );
    if (confirmDelete) {
      // Proceed with the delete action
      console.log(`Deleted ID: ${id}`);
    }
  };

  const handleRedirect = () => {
    navigate('/admin/user-detail');
  }
  
  const data = [
    {
      id: 1,
      name: "name1",
      email: "xyz@gmail.com",
      mobile: "885945678",
      balance: "0.00 USD",
    },

  ];

  const columns = [
    {
      name: "SN",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "NAME",
      selector: (row) => row.name,
    },
    {
      name: "EMAIL",
      selector: (row) => row.email,
      sortable: true
    },
    {
      name: "MOBILE",
      selector: (row) => row.mobile,
    },
    {
      name: "BALANCE",
      selector: (row) => row.balance,
    },
    {
      name: 'ACTION',
      cell: row => (
        <div>
          <button 
            onClick={() => handleRedirect()} 
            className="btn btn-link"
            title="Edit"
          >
            <FontAwesomeIcon icon={faDesktop} />
          </button>
        </div>
      ),
    },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <ResultsList
        data={data}
        columns={columns}
        handleShow={handleShow}
      />
      <ResultFormDialogue
        show={show}
        handleClose={handleClose}
        handleImageChange={handleImageChange}
        imagePreview={imagePreview}
      />
    </>
  );
}
