import  React, { useState } from "react";
import { Card, CardBody, Col, Nav, CardHeader, Button  } from "react-bootstrap";
import DataTableComponent from "../../../../partials/DataTableComponent";

export default function AllEventMatchList(props) {
    const { data,columns,  handleShow } = props;
    return (
        <>
        <Col xs={12}>
            <Card>
                <CardHeader className="d-flex">
                    <h5>All Event</h5>
                    <div className="ms-auto">
                       <Button type="button"  onClick={handleShow} size="sm">+ Add New</Button>
                    </div>
                </CardHeader>
                <CardBody>
                <DataTableComponent data={data} columns={columns} perPage={3} pageOption={[2, 4, 6]} />
                </CardBody>
            </Card>
        </Col>
        </>
    )
}