import { applyMiddleware } from "redux";
import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from '@redux-devtools/extension';
import { thunk } from "redux-thunk";
import appReducer from "./appReducer";
const store = configureStore(
  { reducer: appReducer },
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;