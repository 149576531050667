import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoginPageContainer } from './views/login/Login.container';
import { DashboardPageContainer } from './views/dashboard/Dashboard.container';
import { PaymentPageContainer } from './views/payment/Payment.container';
import { StoreDataPageContainer } from './views/storeData/StoreData.container';
import { ManageInvoiceListContainer } from './views/manageInvoice/ManageInvoice.container';
import { ManageInvoiceAddorEditPageContainer } from './views/manageInvoice/components/Form.container';
import { UserPageContainer } from './views/user/User.container';
import { ChangePasswordPageContainer } from './views/changePassword/ChangePassword.container';
import { RolePageContainer } from './views/role/Role.container';
import { BannerPageContainer } from './views/banner/Banner.container';
import { MobailBankPageContainer } from './views/Payment-Setting/mobailBank/MobailBank.container';
import { MobailBankFormPageContainer } from './views/Payment-Setting/mobailBank/component/Form.container';
import { BankTransferListContainer } from './views/Payment-Setting/bankTransfer/BankTransfer.container';
import { BankTransferPageContainer } from './views/Payment-Setting/bankTransfer/components/Form.container';
import { RoleAccessPageContainer } from './views/role/RoleAccess.container';
import { LeagueComponentPageContainer } from './views/Game/League/League.container';
import { LeagueFormPageContainer } from './views/Game/League/components/Form.container';
import { TournamentComponentListPageContainer } from './views/Game/Tournament/Tournament.container';
import { TournamentComponentFormPageContainer } from './views/Game/Tournament/components/Form.container';
import { PlayerListPageContainer } from './views/Game/Player/Player.container';
import { PlayerFormPageContainer } from './views/Game/Player/components/Form.container';
import { RewardPageContainer } from './views/promotion/Promotion.container';


import { SystemTemplateFormPageContainer } from './views/System-Setting/systemTemplate/SystemTemplate.container';
import { CustomCssFormPageContainer } from './views/System-Setting/customCss/CustomCss.container';
import { MailPageContainer } from './views/System-Setting/mail/Mail.container';
import { ActiveLiscencePageContainer } from './views/System-Setting/activeLiscence/Mail.container';
import { OneSignalPageContainer } from './views/System-Setting/Notifications/oneSignal/OneSignal.container';
import { EmailPageContainer } from './views/System-Setting/Notifications/email/Email.container';
import { InternationalPageContainer } from './views/Payment-Setting/international/International.container';
import { AllEventMatchPageContainer } from './views/Match/allEventMatch/AllEventMatch.container';
import { RunningEventMatchPageContainer } from './views/Match/runningEventMatch/RunningEventMatch.container';
import { UpcomingEventMatchPageContainer } from './views/Match/upcomingEventMatch/UpcomingEventMatch.container';
import { AllBetsLogPageContainer } from './views/Bets/all-bets-log/AllBetsLog.container';
import { PendingBetsLogPageContainer } from './views/Bets/pending-bets-log/PendingBetsLog.container';
import { WonBetsLogPageContainer } from './views/Bets/won-bets-log/WonBetsLog.container';
import { LostBetsLogPageContainer } from './views/Bets/lost-bets-log/LostBetsLog.container';
import { RefundedBetsLogPageContainer } from './views/Bets/refunded-bets-log/RefundedBetsLog.container';
import { ResultsPageContainer } from './views/results/Results.container';
import { WinPageContainer } from './views/who-will-win/Win.container';
import { ManageUsersPageContainer } from './views/Manage-Users/manageUsers/ManageUsers.container';
import { UserDetailPageContainer } from './views/Manage-Users/userDetail/UserDetail.container';
import { ManageActiveUsersPageContainer } from './views/Manage-Users/manageActiveUsers/ManageActiveUsers.container';
import { ManageBannedUsersPageContainer } from './views/Manage-Users/manageBannedUsers/ManageBannedUsers.container';
import { ManageUnverifiedUsersPageContainer } from './views/Manage-Users/manageUnverifiedUsers/ManageUnverifiedUsers.container';
import { SettingsPageContainer } from './views/settings/Settings.container';
import { TicketPageContainer } from './views/ticket/Ticket.container';
import { PermissionComponentPageContainer } from './views/Acl-Manage/Permissions/Permission.container';
import { AuthUserComponentPageContainer } from './views/Acl-Manage/AuthUser/Authuser.container';
import { RolesComponentPageContainer } from './views/Acl-Manage/Roles/Roles.container';
import { DepositLogComponentPageContainer } from './views/Deposits/DepositLog/DepositLog.container';
import { PendingDepositLogComponentPageContainer } from './views/Deposits/PendingDepositLog/PendingDepositLog.container';
import { RejectedDepositLogComponentPageContainer } from './views/Deposits/RejectedDepositLog/RejectedDepositLog.container';
import { AcceptedDepositLogComponentPageContainer } from './views/Deposits/AcceptedDepositLog/AcceptedDepositLog.container';
import { MatchQuestionsPageContainer } from './views/Match/matchQuestions/MatchQuestions.container';


export const RoutePath = () => (
  <Routes>
    <Route path="/" element={<LoginPageContainer/>} />
    <Route path="/admin/dashboard" element={<DashboardPageContainer/>} />
    <Route path="/admin/payment" element={<PaymentPageContainer/>} />
    <Route path="/admin/stored-data" element={<StoreDataPageContainer/>} />
    <Route path="/admin/manage-invoice" element={<ManageInvoiceListContainer/>} />
    <Route path="/admin/add-invoice" element={<ManageInvoiceAddorEditPageContainer/>} />
    <Route path="/admin/users" element={<UserPageContainer/>} />
    <Route path="/admin/change-password" element={<ChangePasswordPageContainer/>} />
    <Route path="/admin/role" element={<RolePageContainer/>} />
    <Route path='/admin/banner' element={<BannerPageContainer/>}></Route>
    <Route path='/admin/mobail-bank' element={<MobailBankPageContainer/>}></Route>
    <Route path='/admin/add-mobail-bank' element={<MobailBankFormPageContainer/>}></Route>
    <Route path='/admin/bank-transfer' element={<BankTransferListContainer/>}></Route>
    <Route path='/admin/add-bank-transfer' element={<BankTransferPageContainer/>}></Route>
    <Route path='/admin/role-access' element={<RoleAccessPageContainer/>}></Route>
    <Route path='/admin/leagues' element={<LeagueComponentPageContainer/>}></Route>
    <Route path='/admin/create-leagues' element={<LeagueFormPageContainer/>}></Route>
    <Route path='/admin/tournament' element={<TournamentComponentListPageContainer/>}></Route>
    <Route path='/admin/create-tournament' element={<TournamentComponentFormPageContainer/>}></Route>
    <Route path='/admin/players' element={<PlayerListPageContainer/>}></Route>
    <Route path='/admin/create-player' element={<PlayerFormPageContainer/>}></Route>
    <Route path='/admin/rewards' element={<RewardPageContainer/>}></Route>
    <Route path='/admin/create-rewards' element={<RewardPageContainer/>}></Route>

    <Route path='/admin/system-template' element={<SystemTemplateFormPageContainer/>}></Route>
    <Route path='/admin/custom-css' element={<CustomCssFormPageContainer/>}></Route>
    <Route path='/admin/mail' element={<MailPageContainer />}></Route>
    <Route path='/admin/active-liscence' element={<ActiveLiscencePageContainer/>}></Route>
    <Route path='/admin/one-signal' element={<OneSignalPageContainer/>}></Route>
    <Route path='/admin/email' element={<EmailPageContainer/>}></Route>
    <Route path='/admin/international' element={<InternationalPageContainer/>}></Route>

    <Route path='/admin/match/all-event-match' element={<AllEventMatchPageContainer/>}></Route>
    <Route path='/admin/match/running-event-match' element={<RunningEventMatchPageContainer/>}></Route>
    <Route path='/admin/match/upcoming-event-match' element={<UpcomingEventMatchPageContainer/>}></Route>
    <Route path='/admin/match/question' element={<MatchQuestionsPageContainer/>}></Route>
    <Route path='/admin/all-bets-log' element={<AllBetsLogPageContainer/>}></Route>
    <Route path='/admin/pending-bets-log' element={<PendingBetsLogPageContainer/>}></Route>
    <Route path='/admin/won-bets-log' element={<WonBetsLogPageContainer/>}></Route>
    <Route path='/admin/lost-bets-log' element={<LostBetsLogPageContainer/>}></Route>
    <Route path='/admin/refunded-bets-log' element={<RefundedBetsLogPageContainer/>}></Route>

    <Route path='/admin/result' element={<ResultsPageContainer/>}></Route>
    <Route path='/admin/who-will-win' element={<WinPageContainer/>}></Route>

    <Route path='/admin/manage-all-users' element={<ManageUsersPageContainer/>}></Route>
    <Route path='/admin/user-detail' element={<UserDetailPageContainer/>}></Route>
    <Route path='/admin/manage-active-users' element={<ManageActiveUsersPageContainer/>}></Route>
    <Route path='/admin/manage-banned-users' element={<ManageBannedUsersPageContainer/>}></Route>
    <Route path='/admin/manage-unverified-users' element={<ManageUnverifiedUsersPageContainer/>}></Route>

    <Route path='/admin/setting' element={<SettingsPageContainer/>}></Route>
    <Route path='/admin/ticket' element={<TicketPageContainer/>}></Route>

    <Route path='/admin/permission' element={<PermissionComponentPageContainer/>}></Route>
    <Route path='/admin/auth-users' element={<AuthUserComponentPageContainer/>}></Route>
    <Route path='/admin/roles' element={<RolesComponentPageContainer/>}></Route>
    <Route path='/admin/deposit-log' element={<DepositLogComponentPageContainer/>}></Route>
    <Route path='/admin/pending-deposit-log' element={<PendingDepositLogComponentPageContainer/>}></Route>
    <Route path='/admin/rejected-deposit-log' element={<RejectedDepositLogComponentPageContainer/>}></Route>
    <Route path='/admin/accepted-deposit-log' element={<AcceptedDepositLogComponentPageContainer/>}></Route>


  </Routes>
);





