const initialState = {
    count: 0,
  };

export const loginReducer = (state = initialState, action) => {
switch (action.type) {
    case 'INCREMENT':
    return { ...state, count: state.count + 1 };
    case 'DECREMENT':
    return { ...state, count: state.count - 1 };
    default:
    return state;
}
};

 