import  React, { useState } from "react";
import BannerList from './components/List';
import BannerFormDialogue from "./components/Form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faTrash } from '@fortawesome/free-solid-svg-icons';
export default function BannerPage() {
    const [show, setShow] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setImagePreview(null);
        setShow(true)
    }
    const  handleEdit = (row) => {
      console.log(`Edit clicked for ID: ${row.id}`);
      setShow(true)
    }

    const handleDelete = (id) => {
      const confirmDelete = window.confirm(`Are you sure you want to delete ID: ${id}?`);
      if (confirmDelete) {
        // Proceed with the delete action
        console.log(`Deleted ID: ${id}`);
      }
    }
    const data = [
        { id: 1, name: '', image: '/uploads/no-image.png', status: 1 },
        { id: 2, name: '', image: '/uploads/no-image.png', status: 0 },
    ];
    const columns = [
        {
          name: 'ID',
          selector: row => row.id,
          sortable: true,
        },
        {
          name: 'Image',
          cell: row => <img src={row.image} alt="item" width="50" />,
        },
        {
          name: 'Status',
          selector: row => row.status === 1 ? 'Active' : 'Inactive',
          sortable: true,
        },
        {
          name: 'Action',
          cell: row => (
            <div>
              <button 
                onClick={() => handleEdit(row)} 
                className="btn btn-link"
                title="Edit"
              >
                <FontAwesomeIcon icon={faPencilAlt } />
              </button>
              <button 
                onClick={() => handleDelete(row.id)} 
                className="btn btn-link text-danger"
                title="Delete"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          ),
        },
      ];
      
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        
        }
    }
    return(
        <>
        <BannerList data={data} columns={columns} handleShow={handleShow}/>
        <BannerFormDialogue show={show} handleClose={handleClose} handleImageChange={handleImageChange} imagePreview={imagePreview} />
        </>
    )
    
}