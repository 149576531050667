import  React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import TournamentListPage from './components/List.component';
import TournamentFormDialogue from "./components/Form";

export default function TournamentComponentPage() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
      // navigate('/admin/create-tournament');
      setShow(true)
    }
    const handleEdit = (row) => {
      // navigate('/admin/create-tournament');
      setShow(true)
    }
    const handleDelete = (id) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete ID: ${id}?`);
        if (confirmDelete) {
            console.log(`Deleted ID: ${id}`);
        }
    }
    const columns = [
        {
          name: 'ID',
          selector: row => row.id,
          sortable: true,
        },
        {
          name: 'Name',
          selector: row => row.name,
          sortable: true,
        },
        {
          name: 'Status',
          sortable: true,
          cell: row => (
            <div>
              <span style={{ fontSize: '14px' }} className={`badge text-white ${row.status === 'Active' ? 'text-bg-warning' : 'text-bg-danger'}`}>
                {row.status === 'Active' ? 'Active' : 'Inactive'}
              </span>
            </div>
          ),
        },
        {
            name: 'Action',
            cell: row => (
              <div>
                <button 
                  onClick={() => handleEdit(row)} 
                  className="btn btn-secondary"
                  title="Edit"
                >
                  <FontAwesomeIcon icon={faPencilAlt } />
                </button>
              </div>
            ),
          },
      ];
      const data = [
        { id: 1, name: 'IPL', status: 'Active' },
        { id: 2, name: 'T20', status: 'Inactive' }
    ];
   
    return(
        <>
            <TournamentListPage data={data} columns={columns}  handleShow={handleShow}/>
            <TournamentFormDialogue
            show={show}
            handleClose={handleClose}
          />
        </>
    )
}