import React, { useState } from "react";
import { Button, Modal, Form, FormControl, Row, Col } from "react-bootstrap";

export default function LeagueFormDialogue(props) {
  const { show, handleClose, handleImageChange, imagePreview } = props;
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit League</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col xs={12} sm={12} lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Name</b>
                  </Form.Label>
                  <Form.Control type="text" placeholder="" />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Status</b>
                  </Form.Label>
                  <Form.Select style={{padding: '.7rem 1rem'}} aria-label="Default select example">
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary">
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
