import  React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faQuestion  } from '@fortawesome/free-solid-svg-icons';
import AllEventFormDialogue from "./components/Form";
import AllEventMatchList from "./components/List";
import { useNavigate } from "react-router-dom";
export default function AllEventMatchPage() {
    const [show, setShow] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const navigate = useNavigate()

    const handleNavigate = () =>{
      navigate('/admin/match/question')
    }
    
    const handleClose = () => setShow(false);

    const handleShow = () => {
        setImagePreview(null);
        setShow(true)
    }
    const  handleEdit = (row) => {
      console.log(`Edit clicked for ID: ${row.id}`);
      setShow(true)
    }

    const handleDelete = (id) => {
      const confirmDelete = window.confirm(`Are you sure you want to delete ID: ${id}?`);
      if (confirmDelete) {
        // Proceed with the delete action
        console.log(`Deleted ID: ${id}`);
      }
    }
    const data = [
      { id: 1, name: 'Gujrat Titans', name2: 'Delhi Capitals', category: 'Cricket', tournament: 'IPL', startTime: '14 Apr 2025, 10:00 AM', endTime: '14 Apr 2025, 10:00 AM' , amount: '4500', status: 1 },
      { id: 2, name: 'Mumbai Indians', name2: 'Sunrisers Hyderabad', category: 'Cricket', tournament: 'T20', startTime: '14 Apr 2025, 10:00 AM', endTime: '14 Apr 2025, 10:00 AM', amount: '4500', status: 0 },
    ];
    

    const columns = [
  {
    name: 'SL',
    selector: row => row.id,
    sortable: true,
  },
  {
    name: 'Name',
    selector: row => (
      <div style={{ textAlign: 'center' }}>
        <div className="my-1">{row?.name || 'Unknown Team 1'}</div>
        <div className="badge text-bg-info rounded-pill text-white">vs</div>
        <div className="my-1">{row?.name2 || 'Unknown Team 2'}</div>
      </div>
    ),
  },
  {
    name: 'Category | Tournament',
    selector: row => (
      <div style={{ textAlign: 'center' }}>
        <div className="mb-1">{row?.category}</div>
        <div>{row?.tournament}</div>
      </div>
    ),
    sortable: true,
  },
  {
    name: 'Start At | End At',
    selector: row => (
      <div style={{ textAlign: 'center' }}>
        <div className="mb-1">{row?.startTime}</div>
        <div>{row?.endTime}</div>
      </div>
    ),
    sortable: true,
  },
  {
    name: 'Predict Amount',
    selector: row => row.amount,
    sortable: true,
  },
  {
    name: 'Status',
    sortable: true,
    cell: row => (
      <div>
        <span style={{ fontSize: '14px' }} className={`badge ${row.status === 1 ? 'text-bg-success' : 'text-bg-danger'}`}>
          {row.status === 1 ? 'Active' : 'Inactive'}
        </span>
      </div>
    ),
  },
  {
    name: 'Action',
    cell: row => (
      <div>
        <button 
          onClick={() => handleEdit(row)} 
          className="btn btn-secondary me-2"
          title="Edit"
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </button>
        <button 
          onClick={() => handleNavigate()} 
          className="btn btn-info text-white"
          title="Delete"
        >
          <FontAwesomeIcon icon={faQuestion } />
        </button>
      </div>
    ),
  },
];
      
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        
        }
    }
    return(
        <>
        <AllEventMatchList data={data} columns={columns} handleShow={handleShow}/>
        <AllEventFormDialogue show={show} handleClose={handleClose} handleImageChange={handleImageChange} imagePreview={imagePreview} />
        </>
    )
    
}