import React, { useState,useMemo } from 'react';
import { Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

const DataTableComponent = ({ data,columns, perPage,pageOption }) => {
 const [filterText, setFilterText] = useState('');
 
 const filteredItems = useMemo(() => {
  return data.filter(item =>
    item.name?.toLowerCase().includes(filterText.toLowerCase())
  );
}, [data, filterText]);
  return (
    <>
    <Col xs={3} className="float-end">
      <input
        type="text"
        placeholder="Filter by name"
        value={filterText}
        onChange={e => setFilterText(e.target.value)}
        className="form-control mb-3 "
        style={{padding:'.5rem 1rem'}}
      />
    </Col>
    <div>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={pageOption}
        noDataComponent="No data available"
        highlightOnHover
        striped
      />
    </div>
    </>
  );
};

export default DataTableComponent;
